import {
	type ErrorResponse,
	isRouteErrorResponse,
	useParams,
	useRouteError,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import {getErrorMessage} from "~/helper/misc";

type StatusHandler = (info: {
	error: ErrorResponse
	params: Record<string, string | undefined>
}) => JSX.Element | null

export function GeneralErrorBoundary({
	defaultStatusHandler = ({ error }) => (
		<p>
			{error.status} {error.data}
		</p>
	),
	statusHandlers,
	unexpectedErrorHandler = error => <div>
		<p>
			{getErrorMessage(error)}
		</p>
	</div>,
}: {
	defaultStatusHandler?: StatusHandler
	statusHandlers?: Record<number, StatusHandler>
	unexpectedErrorHandler?: (error: unknown) => JSX.Element | null
}) {
	const error = useRouteError()
	if (!isRouteErrorResponse(error) || error.status >= 500) {
		captureRemixErrorBoundaryError(error)
	}
	const params = useParams()

	if (typeof document !== 'undefined') {
		console.error(error)
	}

	return (
		<div className="flex items-center justify-center p-20 text-h2 bg-primary h-screen text-white">
			<div className="flex flex-col gap-6 bg-secondary px-20 py-10 container">
				{isRouteErrorResponse(error)
					? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
						error,
						params,
					})
					: unexpectedErrorHandler(error)}
			</div>
		</div>
	);
}
